import { conf } from 'outlinejs';
import { BaseModel, mergeBackboneOptions } from '../core/models';

export class ProfessionalOrderHeader extends BaseModel {
  get urlRoot() {
    return conf.settings.PROFESSIONAL_ORDER_HEADER_URL;
  }

  get idAttribute() {
    return 'id';
  }

  get id() {
    return this.get('id');
  }

  get orderId() {
    return this.get('orderId');
  }

  get state() {
    return this.get('state');
  }

  set state(value) {
    this.set('state', value);
  }

  get orderGuid() {
    return this.get('orderGuid');
  }

  set orderGuid(value) {
    this.set('orderGuid', value);
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get shopCode() {
    return this.get('shopCode');
  }

  /* todo: sto usando il vecchio messaggio per inviare i dati tramite sendGAData , da sistemare il prima possibile questa schifezza */
  get price() {
    return this.get('price');
  }

  get totalDiscount() {
    return this.get('totalDiscount');
  }

  get currencyCode() {
    return this.get('currencyCode');
  }

  get vat() {
    return this.get('vat');
  }

  get totalShipping() {
    return this.get('totalShipping');
  }

  get shippingCost() {
    return this.get('shippingCost');
  }

  get couponCodes() {
    return this.get('couponCodes');
  }

  get paymentType() {
    return this.get('paymentType');
  }

  set paymentType(value) {
    this.set('paymentType', value);
  }

  get paymentUrl() {
    return this.get('paymentUrl');
  }

  set paymentUrl(value) {
    this.set('paymentUrl', value);
  }

  get cartGuid() {
    return this.get('cartGuid');
  }

  set cartGuid(value) {
    this.set('cartGuid', value);
  }

  get redirectUri() {
    return this.get('redirectUri');
  }

  set redirectUri(value) {
    this.set('redirectUri', value);
  }

  current() {
    return this.set('id', 'current');
  }

  fetch(options = {}) {
    const params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    };
    options = mergeBackboneOptions(options, params);
    return super.fetch(options);
  }

  save(options = {}) {
    this.current();
    const params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    };
    options = mergeBackboneOptions(options, params);
    return super.save(this.attributes, { options });
  }

  create(options = {}) {
    this.url = `${this.urlRoot}current`;
    return super.save(this.attributes, options);
  }
}

export class ProfessionalCartHeader extends BaseModel {
  get urlRoot() {
    return conf.settings.PROFESSIONAL_CART_HEADER_URL;
  }

  get idAttribute() {
    return 'id';
  }

  get id() {
    return this.get('id');
  }

  current() {
    return this.set('id', 'current');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  get cartState() {
    return this.get('cartState');
  }

  set cartState(value) {
    this.set('cartState', value);
  }

  set orderGuid(value) {
    this.set('orderGuid', value);
  }

  get orderGuid() {
    return this.get('orderGuid');
  }

  get orderWebNumber() {
    return this.get('orderWebNumber');
  }

  get currencyCode() {
    return this.get('currencyCode');
  }

  get confirmed() {
    return this.get('confirmed');
  }

  get numberOfProducts() {
    return this.get('numberOfProducts');
  }

  get formattedSubTotalWithoutVoucher() {
    return this.get('formattedSubTotalWithoutVoucher');
  }

  get totalVoucher() {
    return this.get('totalVoucher');
  }

  get formattedTotalVoucher() {
    return this.get('formattedTotalVoucher');
  }

  get formattedSubTotal() {
    return this.get('formattedSubTotal');
  }

  get formattedSubTotalVat() {
    return this.get('formattedSubTotalVat');
  }

  get formattedSubTotalWithVat() {
    return this.get('formattedSubTotalWithVat');
  }

  get formattedShippingCost() {
    return this.get('formattedShippingCost');
  }

  get formattedTotalVat() {
    return this.get('formattedTotalVat');
  }

  get formattedTotalPriceWithVat() {
    return this.get('formattedTotalPriceWithVat');
  }

  get totalPriceWithVat() {
    return this.get('totalPriceWithVat');
  }

  get directPayment() {
    return this.get('directPayment');
  }

  get hasOnlinePayment() {
    return this.get('hasOnlinePayment');
  }

  get couponCodes() {
    return this.get('couponCodes');
  }

  set couponCodes(value) {
    this.set('couponCodes', value);
  }

  set softwareCode(value) {
    this.set('softwareCode', conf.settings.PROFESSIONAL_SOFTWARE_CODE);
  }

  get couponsLimit() {
    return 1;
  }

  get deliveryDays() {
    return this.get('deliveryDays');
  }

  get warningMessage() {
    return this.get('warningMessage');
  }

  get cartIsEnabled() {
    if (this.cartState) {
      return conf.settings.CART_ENABLE_STATUS.includes(this.cartState.toLowerCase());
    }
    return false;
  }

  fetchWithDeliveryTimes(options = {}) {
    const params = {
      data: {
        includeDeliveryTimes: 'true',
        softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
      }
    };
    options = mergeBackboneOptions(options, params);
    return this.fetch(options);
  }

  fetch(options = {}) {
    this.current();
    const params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    };
    options = mergeBackboneOptions(options, params);
    return super.fetch(options);
  }

  save(options = {}) {
    this.current();
    const params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE }
    };
    options = mergeBackboneOptions(options, params);
    return super.save(this.attributes, options);
  }
}

export class ProfessionalOrderRow extends BaseModel {
  get urlRoot() {
    return conf.settings.PROFESSIONAL_ORDER_ROWS_URL;
  }

  get idAttribute() {
    return 'guid';
  }

  get id() {
    return this.get('id');
  }

  get guid() {
    return this.get('guid');
  }

  get preOrderId() {
    return this.get('preOrderId');
  }

  get productType() {
    return this.get('productType');
  }

  get productCode() {
    return this.get('productCode');
  }

  get localizedName() {
    return this.get('localizedName');
  }

  get productPrice() {
    return this.get('productPrice');
  }

  get unitPrice() {
    return this.get('unitPrice');
  }

  get quantity() {
    return this.get('quantity');
  }

  get voucherCode() {
    return this.get('voucherCode');
  }

  get currencyCode() {
    return this.get('currencyCode');
  }
}
