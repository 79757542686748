import { routers, routing } from 'outlinejs';

import { OrderConfirmationController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('order-confirmation:main', OrderConfirmationController),
      ':orderGuid:': routing.url('order-confirmation:orderGuid', OrderConfirmationController)
    };
  }
}
