import { runtime } from '@outlinejs/contexts';
import { conf } from 'outlinejs';

import { EpocaBaseController } from '../core/controllers';
import { navigateTo, sleep } from '../core/utils';
import EventTracker from '../core/eventTracker';
import Logger from '../core/logger';

import { ProfessionalOrderHeader } from './models';
import { OrderConfirmationView } from './views';
import { ProfessionalOrderRowsCollection } from './managers';

export class OrderConfirmationController extends EpocaBaseController {
  get view() {
    return OrderConfirmationView;
  }

  get context() {
    return Object.assign(super.context, {
      orderHeader: this.orderHeader,
      orderRows: this.orderRows,
      topMenuBarVisible: this.topMenuBarVisible,
      confirmed: this.confirmed
    });
  }

  async initContentProps(orderId) {
    await super.initContentProps();
    this.topMenuBarVisible = false;
    this.confirmed = false;
    this.webOrderId = null;

    this.orderHeader = new ProfessionalOrderHeader({ id: orderId });
    this.orderRows = new ProfessionalOrderRowsCollection();
  }

  async init(orderId) {
    await this.initContentProps(orderId);

    this.startInitialRendering();

    if (runtime.isClient) {
      await this.loadOrderHeader();
      this.webOrderId = this.orderHeader.orderId;
      await this.loadOrderRows();

      // load cart header to create new cart
      this.loadCartHeader();

      // Need to send Analytics / Amplitude Data
      await this.setFacebookPurchase();
      await this.sendGA4Purchase();
      EventTracker.logPurchase(this.customerUser, this.orderHeader, this.orderRows);

      if (this.confirmed) {
        navigateTo(
          this.request,
          this.response,
          'thankyou:orderGuid',
          { orderGuid: this.orderHeader.orderId },
          true
        );
      } else {
        navigateTo(this.request, this.response, 'thankyou:orderGuid', { orderGuid: null }, true);
      }
    }
  }

  paymentStatusChecked(retryNumber, maxRetries) {
    let result = conf.settings.ORDER_CONFIRMED_STATUS.includes(
      this.orderHeader.state.toLowerCase()
    );
    if (retryNumber === maxRetries) {
      result = true;
    }
    return result;
  }

  async fetchOrder() {
    const maxRetries = 5;
    for (let retry = 1; retry <= maxRetries; retry++) {
      await this.orderHeader.fetch();
      this.confirmed = this.paymentStatusChecked(retry, maxRetries);
      if (this.confirmed) {
        break;
      } else {
        await sleep(2000);
      }
    }
  }

  async getOrderRows() {
    await this.orderRows.fetchOrderRowsWithWebOrderId(this.webOrderId);
  }

  async loadOrderHeader() {
    try {
      await this.fetchOrder();
      this.render(this.context);
    } catch (error) {
      Logger.error('OrderConfirmationController.loadOrderHeader', {
        error,
        context: this.context
      });
    }
  }

  async loadOrderRows() {
    try {
      await this.getOrderRows();
      this.render(this.context);
    } catch (error) {
      Logger.error('OrderConfirmationController.loadOrderRows', {
        error,
        context: this.context
      });
    }
  }

  async setFacebookPurchase() {
    if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
      // add Facebook data
      window.total_order = this.orderHeader.price; //eslint-disable-line
      window.currency = this.orderHeader.currencyCode; //eslint-disable-line
    }
  }

  async sendGA4Purchase() {
    if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
      try {
        const products = [];
        this.orderRows.map((row, index) => {
          products.push({
            item_id: row.preOrderId.toString(),
            item_name: row.localizedName.replace(/\s/g, ''),
            affiliation: 'Album Epoca',
            coupon: row.voucherCode,
            index,
            price: Number(row.productPrice),
            quantity: Number(row.quantity)
          });
        });

        // Discount is only available for each row
        if (this.orderHeader.totalDiscount && this.orderHeader.totalDiscount > 0) {
          const discount = Number((this.orderHeader.totalDiscount / products.length).toFixed(2));
          products.forEach((product) => (product.discount = discount));
        }

        const data = {
          event: 'purchase',
          ecommerce: {
            transaction_id: this.orderHeader.orderId.toString(),
            value: Number(this.orderHeader.price),
            tax: Number(this.orderHeader.vat),
            shipping: Number(this.orderHeader.totalShipping),
            currency: this.orderHeader.currencyCode,
            coupon:
              this.orderHeader.couponCodes.length > 0 ? this.orderHeader.couponCodes[0] : null,
            items: products
          }
        };

        dataLayer.push({ ecommerce: null }); //eslint-disable-line
        dataLayer.push(data); //eslint-disable-line
      } catch (err) {
        Logger.error('ThankYouController.sendGA4Purchase - unable to update data object', {
          error: err,
          orderHeader: this.orderHeader,
          orderRows: this.orderRows
        });
      }
    }
  }
}
