import { conf } from 'outlinejs';
import { BaseCollection } from '../core/managers';

import { ProfessionalOrderRow } from './models';

export class ProfessionalOrderRowsCollection extends BaseCollection {
  get name() {
    return 'ProfessionalOrderRow';
  }

  get url() {
    return conf.settings.PROFESSIONAL_ORDER_ROWS_URL;
  }

  get model() {
    return ProfessionalOrderRow;
  }

  async fetchOrderRowsWithWebOrderId(webOrderId) {
    const params = {
      orderId: webOrderId,
      softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE
    };
    return await this.fetch({ data: params });
  }
}
